
import {
  ChromeOutlined,
  QuestionOutlined,
  AuditOutlined,
  CommentOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  AuditOutlined,
  CommentOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UserOutlined
};

const support = {
  id: 'support',
  title: 'Paramètres',
  type: 'group',
  children: [
    /*     {
      id: 'sample-page',
      title: 'Sample Page',
      type: 'item',
      url: '/sample-page',
      icon: icons.ChromeOutlined
    }, */
/*     {
      id: 'parametre-compte',
      title: 'Paramètre du compte',
      type: 'item',
      url: '/parametre-compte',
      icon: icons.UserOutlined
    }, */
   /*  {
      id: 'sample-page',
      title: 'Sample Page',
      type: 'item',
      url: '/sample-page',
      icon: icons.ChromeOutlined
    }, */
    {
        id: 'addusers-page',
        title: 'Gestion utilisateurs',
        type: 'item',
        url: '/addusers-page',
        icon: icons.ChromeOutlined
      },
    {
      id: 'support',
      title: 'Support',
      type: 'item',
      url: 'https://outlook.office365.com/mail/deeplink/compose?mailtouri=mailto%3Acontact%40snsolutions.fr',
      icon: icons.QuestionCircleOutlined,
      external: true,
      target: true
    },
/*     {
      id: 'mention-legale',
      title: 'Mention légale',
      type: 'item',
      url: '/mention-legale',
      icon: icons.AuditOutlined
    }, */
/*     {
      id: 'confidentialite',
      title: 'Confidentialité',
      type: 'item',
      url: '/confidentialite',
      icon: icons.LockOutlined
    }, */
/*     {
      id: 'feedback',
      title: 'Feedback',
      type: 'item',
      url: '/feedback',
      icon: icons.CommentOutlined
    } */
  ]
};

export default support;
