import { axiosInstance } from '../axios/axios-config';


let getAllAgents = async () => {
  try {
    const response = await axiosInstance.get('/agents');
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'obtention des agents:', error);
    throw error;
  }
};

let getAgentById = async (id) => {
  try {
    const response = await axiosInstance.get(`/agents/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'obtention de l\'agent:', error);
    throw error;
  }
};

let getAgentsBySda = async (sdaName, dateStart, dateEnd) => {
  try {
    const response = await axiosInstance.get('/calls', {
      params: {
        sda: sdaName,
        dateStart: dateStart,
        dateEnd: dateEnd,
      }
    });
    return response.data.globalInfo.boundAgents;
  } catch (error) {
    console.error('Erreur lors de l\'obtention des agents par SDA :', error);
    throw error;
  }
};

if (sessionStorage.getItem('userData') == null) {
  getAllAgents = () => {}
  getAgentById = () => {}
  getAgentsBySda = () => {}
}

export { getAllAgents }

export { getAgentById }

export { getAgentsBySda }
