import React, { useEffect } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LicenseInfo } from '@mui/x-license-pro';
import { useDashboard } from '../../../../context/DashboardContext';
import frLocale from 'date-fns/locale/fr';
import Autocomplete from '@mui/material/Autocomplete';
import useGetAllSda from '../../../../hooks/useGetAllSda';
import useGetAllAgents from '../../../../hooks/useGetAllAgents';
import useGetAgentsBySda from '../../../../hooks/useGetAgentsBySda';
import useGetAllQueues from '../../../../hooks/useGetAllQueues';

LicenseInfo.setLicenseKey('e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y');

dayjs.extend(utc);
dayjs.extend(timezone);

const FilterSelector = () => {
  const { selectedDate, setSelectedDate, selectedAgent, setSelectedAgent, selectedQueue, setSelectedQueue, selectedSda, setSelectedSda } = useDashboard();
  const { data: sdaList = [] } = useGetAllSda();
  const { data: queueList = [] } = useGetAllQueues();
  const { data: allAgents = [] } = useGetAllAgents();
  const { data: agentsBySda = [] } = useGetAgentsBySda(selectedSda?.calledLine, selectedDate[0], selectedDate[1]);

  const shortcutsItems = [
    {
      label: 'Aujourd\'hui',
      getValue: () => {
        const today = dayjs();
        return [today.toDate(), today.toDate()]; // need 2 elements
      },
    },
    {
      label: 'Cette semaine',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week').toDate(), today.endOf('week').toDate()];
      },
    },
    {
      label: 'Semaine dernière',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'days');
        return [prevWeek.startOf('week').toDate(), prevWeek.endOf('week').toDate()];
      },
    },
    {
      label: 'Mois en cours',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month').toDate(), today.endOf('month').toDate()];
      },
    },
    {
      label: 'Réinitialiser',
      getValue: () => [null, null],
    },
  ];

  useEffect(() => {
    const thisWeekShortcut = shortcutsItems.find(item => item.label === 'Cette semaine');
    if (thisWeekShortcut && typeof thisWeekShortcut.getValue === 'function') {
      const thisWeekRange = thisWeekShortcut.getValue();
      setSelectedDate(thisWeekRange);
    }
  }, [setSelectedDate]);

  const handleDateChange = (newValue) => {
    const startDate = newValue[0];
    const endDate = newValue[1];

    if (startDate && endDate) {
      const localStart = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      const localEnd = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
      setSelectedDate([localStart, localEnd]);
    }
  };

  const availableAgents = selectedSda ? agentsBySda : allAgents;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <FormControl fullWidth sx={{ m: 2, width: { xs: '100%', sm: '50%', md: '90%' } }}>
        <DateRangePicker
          slots={{ field: SingleInputDateRangeField }}
          value={selectedDate}
          onChange={handleDateChange}
          slotProps={{
            shortcuts: {
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> au </Box>
              <TextField {...endProps} />
            </>
          )}
        />

        <Autocomplete
          value={selectedSda}
          onChange={(event, newValue) => {
            setSelectedSda(newValue);
          }}
          options={sdaList}
          getOptionLabel={(option) => option.calledLine || 'Option sans nom'}
          isOptionEqualToValue={(option, value) => value && option.calledLine === value.calledLine}
          renderInput={(params) => (
            <TextField {...params} label="Sélectionner un SDA" margin="normal" />
          )}
        />

        <Autocomplete
          value={selectedQueue}
          onChange={(event, newValue) => {
            setSelectedQueue(newValue);
          }}
          options={queueList}
          getOptionLabel={(option) => option.queue || 'Groupe sans nom'}
          renderInput={(params) => (
            <TextField {...params} label="Sélectionner un groupe d'appel" margin="normal" />
          )}
          style={{ marginBottom: 10 }}
        />

        <Autocomplete
          value={selectedAgent}
          onChange={(event, newValue) => {
            setSelectedAgent(newValue);
          }}
          options={availableAgents}
          getOptionLabel={(option) => option.contactName}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.Agent}>
              {option.contactName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionner un agent"
              InputLabelProps={{ style: { marginTop: 6 } }}
            />
          )}
          style={{ marginBottom: 10 }}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default FilterSelector;
