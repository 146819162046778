/* import pages from './pages'; */
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';


const menuItems = {
  items: [dashboard, /* pages, */ support, utilities]
};

export default menuItems;
